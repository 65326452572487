import { apiHttp } from '../axiosApi'
// eslint-disable-next-line object-curly-newline
import { groupAll, groupPath, groupAllActive, groupUpdateLogo, groupByFacility } from '../config/apiRoute'

const { accessToken } = localStorage

export const getGroups = async () => apiHttp('GET', `${groupAll}`)
export const getGroupsActive = async (loading = true) => apiHttp('GET', `${groupAllActive}`, {}, {}, loading, 'v1')
export const getGroupsById = async id => apiHttp('GET', `${groupPath}${id}`)
export const getGroupByFacility = async facilityId => apiHttp('GET', `${groupByFacility}${facilityId}`)
export const createGroup = async body => apiHttp('POST', `${groupPath}`, body)
export const editGroup = async (body, id) => apiHttp('PUT', `${groupPath}${id}`, body)
export const editGroupLogo = async (body, facilityId) => apiHttp('PUT', `${groupUpdateLogo}${facilityId}`, body, {
  'Content-Type': 'multipart/form-data',
  'el-token': `Bearer ${accessToken}`,
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
})
export const deleteGroup = async id => apiHttp('DELETE', `${groupPath}${id}`)
