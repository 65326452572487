export default {
  Billing_to: 'Facturación Para',
  Dashboards: 'Tableros',
  Dashboard: 'Tablero',
  'Fanaty Dashboard': 'Tablero Fanaty',
  'Recording Activity': 'Actividad de Grabación',
  CRM: 'CRM',
  Analytics: 'Analiticas',
  eCommerce: 'eCommerce',
  Calendar: 'Calendario',
  Chat: 'Chat',
  chat_support: 'Chat de Soporte',
  phone_support: 'Número de Teléfono de Soporte',
  'Phone Number': '(888) 965-3262',
  Email: 'Correo electrónico',
  Invoice: 'Factura',
  Invoice_to: 'Factura Para',
  Receipt: 'Recibo',
  List: 'Lista',
  Preview: 'Vista previa',
  Edit: 'Editar',
  Add: 'Agregar',
  User: 'Usuario',
  'User List': 'Lista de usuarios',
  'User View': 'Vista de usuario',
  'APPS AND PAGES': 'APLICACIONES Y PÁGINAS',
  Pages: 'Páginas',
  Authentication: 'Autenticación',
  'Login V1': 'Iniciar sesión V1',
  'Login V2': 'Iniciar sesión V2',
  'Register V1': 'Registro V1',
  'Register V2': 'Registro V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscelaneos',
  'Under Maintenance': 'En mantenimiento',
  Error: 'Error',
  'Coming Soon': 'Próximamente',
  'Not Authorized': 'No autorizado',
  'Knowledge Base': 'Base de conocimientos',
  'Account Settings': 'Configuración de la cuenta',
  Pricing: 'Precios',
  FAQ: 'Preguntas frecuentes',
  'USER INTERFACE': 'INTERFAZ DE USUARIO',
  Typography: 'Tipografía',
  Icons: 'Iconos',
  Gamification: 'Gamificación',
  Cards: 'Tarjetas',
  Basic: 'Básico',
  Statistics: 'Estadísticas',
  Advance: 'Avance',
  Actions: 'Acciones',
  Action: 'Acción',
  Chart: 'Gráfico',
  Components: 'Componentes',
  Alert: 'Alerta',
  Avatar: 'Avatar',
  Badge: 'Insignia',
  Button: 'Botón',
  Menu: 'Menú',
  'Expansion Panel': 'Panel de expansión',
  Dialog: 'Diálogo',
  Pagination: 'Paginación',
  Tabs: 'Pestañas',
  Tooltip: 'Información sobre herramientas',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Paso a paso',
  Timeline: 'Línea de tiempo',
  Treeview: 'Vista de árbol',
  'FORMS & TABLES': 'FORMULARIOS Y TABLAS',
  'Forms Elements': 'Elementos de Formularios',
  Autocomplete: 'Autocompletar',
  Checkbox: 'Casilla de verificación',
  Combobox: 'Cuadro combinado',
  'File Input': 'Entrada de archivo',
  Radio: 'Radio',
  'Range Slider': 'Control deslizante de rango',
  Select: 'Seleccionar',
  Slider: 'Control deslizante',
  Switch: 'Interruptor',
  Textarea: 'Textarea',
  Textfield: 'Campo de texto',
  'Date Picker': 'Selector de fecha',
  'Month Picker': 'Selector de mes',
  'Time Picker': 'Selector de tiempo',
  Rating: 'Calificación',
  'Form Layout': 'Diseño de formulario',
  'Form validation': 'Validación de formulario',
  Tables: 'Tablas',
  'Simple Table': 'Tabla simple',
  Datatable: 'Tabla de datos',
  'CHARTS AND MAPS': 'GRÁFICOS Y MAPAS',
  Charts: 'Gráficos',
  'Apex Chart': 'Gráfico de Apex',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Mapas de folletos',
  Others: 'Otros',
  'Menu Levels': 'Niveles de menú',
  'Menu Level 2.1': 'Nivel de menú 2.1',
  'Menu Level 2.2': 'Nivel de menú 2.2',
  'Menu Level 3.1': 'Nivel de menú 3.1',
  'Menu Level 3.2': 'Nivel de menú 3.2',
  'Disabled Menu': 'Menú desactivado',
  'Raise Support': 'Elevar soporte',
  Documentation: 'Documentación',
  'Charts & Maps': 'Gráficos y mapas',
  Apps: 'Aplicaciones',
  'User Interface': 'Interfaz de usuario',
  'Forms & Tables': 'Formularios y tablas',
  'Buttom Options': 'Opciones de botón',
  VIEWS: 'VISTAS',
  Users: 'Usuarios',
  Transactions: 'Transacciones',
  'Access Control': 'Control de Acceso',
  'Payment Methods': 'Métodos de Pago',
  'Order Video': 'Ordenar Video',
  'Order Videos': 'Ordenar Videos',
  'Order History': 'Historial de Ordenes',
  'Payment History': 'Historial de Pago',
  Configurations: 'Configuraciones',
  Sports: 'Deportes',
  Ranking: 'Grupos',
  'Ranking Groups': 'Grupos de Ranking',
  'Cancellation Reasons': 'Motivos de Cancelación',
  Fields: 'Campos',
  'Field Monitoring': 'Monitoreo',
  Facilities: 'Predios',
  Cameras: 'Cámaras',
  'App Users': 'Usuarios de App',
  'Console Users': 'Usuarios de Consola',
  'User Activity': 'Actividad de Usuarios',
  Highlights: 'Destacados',
  Groups: 'Grupos',
  Roles: 'Roles',
  Notifications: 'Notificaciones',
  Reservations: 'Reservaciones',
  Booking: 'Reserva',
  Bookings: 'Reservas',
  'Regular Booking': 'Reserva Regular',
  'List For Sale': 'Lista de venta',
  'Block Times': 'Tiempos de bloqueo',
  'Recurring Reservations': 'Reservaciones Recurrentes',
  'Recurring Bookings': 'Reservas Recurrentes',
  'Recurring Booking': 'Reserva Recurrente',
  Metrics: 'Métricas',
  Communications: 'Comunicaciones',
  'Onsite Payments Methods': 'Onsite Payments Methods',
  'Blocked Times': 'Horarios bloqueados',
  'Video Blocked Times': 'Horarios bloqueados de Video',
  'Console Notifications': 'Notificaciones Console',
  'App Notifications': 'Notificationes de App',
  'Recurring Orders': 'Ordenes Recurrentes',
  'Players Group': 'Grupo de Jugadores',
  Reservation: 'Reservación',

  FACILITY: 'PREDIO',
  Schedule: 'Cronograma',
  Contacts: 'Contactos',
  Contact: 'Contacto',
  Categories: 'Categorias',
  'Contacts Categories': 'Categorias de Contactos',
  'Contact Category': 'Categoría de Contacto',
  Category: 'Categoría',
  Settings: 'Ajustes',
  ORGANIZATION: 'ORGANIZACIÓN',
  Reports: 'Informes',
  Billing: 'Facturación',
  'Billing History': 'Historial de Facturación',
  Sales: 'Ventas',
  Inventory: 'Inventario',
  current_inventory: 'Inventario Actual',
  'Products Categories': 'Categorías de Producto',
  Products: 'Productos',
  'Inventory Adjustment': 'Ajuste de Inventario',
  'POS Settings': 'Ajustes POS',
  FANATY: 'FANATY',
  Organizations: 'Organizaciones',
  Application: 'Applicación',
  Updates: 'Actualizaciones',
  POS: 'POS',
  Support: 'Soporte',
  'Full Videos': 'Vídeos completos',
  SUPPORT: 'SUPPORT',
  Notification: 'Notification',
  Recordings: 'Grabaciones',
  Recording: 'Grabación',
  'Org Settings ***': 'Ajustes Org',
  'Facility Settings ***': 'Ajustes Facility ***',
  'Payments ***': 'Pagos ***',
  'Contacts ***': 'Contactos ***',
  v2: 'v2',

  Players: 'Jugadores',
  Videos: 'Videos',
  Payments: 'Pagos',
  'Fanaty Payments': 'Fanaty Pagos',
  'POS Payments': 'POS Pagos',
  'Facility Payments': 'Pagos a Complejos',
  Checkout: 'Checkout',
  Activity: 'Actividad',
  Terminals: 'Terminales',
  Loading: 'Cargando',
  search: 'Buscar',
  actions: 'Acciones',
  locale: 'es-ES',
  filter_by: 'filtrar por',
  minutes: {
    min_2: '2 Minutos',
    min_5: '5 Minutos',
    min_60: '60 Minutos',
    min_90: '90 Minutos',
    min_120: '120 Minutos',
    min_150: '150 Minutos',
    min_180: '180 Minutos',
    min_210: '210 Minutos',
    min_240: '240 Minutos',
    min_270: '270 Minutos',
    min_300: '300 Minutos',
    min_330: '330 Minutos',
    min_360: '360 Minutos',
  },
  required: {
    null: ' es requerido',
    'length 0': ' debe tener menos de 0 caracteres',
    'length 3': ' debe tener menos de 3 caracteres',
    'length 4': ' debe tener menos de 4 caracteres',
    'length 5': ' debe tener menos de 5 caracteres',
    'length 10': ' debe tener menos de 10 caracteres',
    'length 15': ' debe tener menos de 15 caracteres',
    'length 20': ' debe tener menos de 20 caracteres',
    'length 25': ' debe tener menos de 25 caracteres',
    'length 40': ' debe tener menos de 40 caracteres',
    'length 50': ' debe tener menos de 50 caracteres',
    'length 100': ' debe tener menos de 100 caracteres',
    'length 200': ' debe tener menos de 200 caracteres',
    'length 250': ' debe tener menos de 250 caracteres',
    length: 'debe ser menor o igual que ',
    email: 'Correo debe ser válido',
    nullGeneral: 'Este campo es requerido',
    invalidFormat: 'Formato inválido',
    label: '(*) Información Requerida',
    labelShort: '(*) Req.',
    needData: 'Falta datos que ingresar, por favor completelos',
  },
  tooltip: {
    new: 'Nuevo',
    add: 'Agregar',
    create: 'Crear',
    save: 'Guardar',
    edit: 'Editar',
    update: 'Actualizar',
    delete: 'Borrar',
    show: 'Detalles',
    cancel: 'Cancelar',
    open_tab: 'Abrir Tab',
    next_tab: 'Próximo Tab',
    back: 'Volver',
    list: 'Listado',
    back_table: 'Volver al listado',
    info: 'Información',
    search: 'Buscar',
    actions: 'Acciones',
    refresh: 'Refrescar',
    left: 'Left',
    right: 'Right',
    order: 'Ordenar',
    no_data: 'No hay videos para mostrar',
    enable: 'Habilitar',
    disable: 'Inhabilitar',
    submit: 'Enviar',
    default: 'Predeterminado',
    copied: 'Copiado',
    not_copy: 'No se pudo copiar',
    view_detail: 'ver Detalle',
    view_video: 'Ver Video',
    confirm_order: 'Confirmar Orden',
    edit_order: 'Editar Orden',
    close: 'Cerrar',
    closed: 'Cerrado',
    download: 'Descargar',
    print: 'Imprimir',
    print_selection: 'Imprimir Seleciión',
    generating_pdf: 'Generando PDF',
    continue: 'Continuar',
    print_receivet: 'Imprimir recibo',
    generate_report: 'Generar Reporte',
    play_video: 'Reproducir video',
    yesterday: 'Ayer',
    today: 'Hoy',
    tomorrow: 'Mañana',
    this_week: 'Esta Semana',
    next_week: 'Siguiente Week',
    prev_week: 'Semana Previa',
    prev: 'Ant',
    next: 'Sig',
    export: 'Exportar',
    filters: 'Filtros',
    accept: 'Aceptar',
    confirm: 'Confirmar',
    add_image: 'Agregar Imagenes',
    detail: 'Detalle',
    exit: 'Salir',
    send: 'Enviar',
    resend: 'Reenviar',
    share: 'Compartir',
    at_minute: 'en el minuto',
    enabled: 'Activado',
    disabled: 'Desactivado',
    mark_as_paid: 'Marcar pagado',
    mark_all: 'Marcar todos',
    uncheck_all: 'Desmarcar todo',
    unmark_all: 'Desmarcar todo',
    all: 'Todos',
    unpaid: 'Sin Pagar',
    discard: 'Descartar',
    clear: 'Limpiar',
  },
  weekdays: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sabado',
    sunday: 'Domíngo',
  },
  weekdays_short: {
    mon: 'Lu',
    tue: 'Ma',
    wed: 'Mi',
    thu: 'Ju',
    fri: 'Vi',
    sat: 'Sa',
    sun: 'Do',
  },
  time: {
    weeks: 'Semanas',
    week: 'Semana',
    months: 'Meses',
    month: 'Mes',
    years: 'Años',
    year: 'Año',
  },
  select: {
    role: 'Seleccionar Rol',
    facility: 'Seleccionar Predio',
    status: 'Seleccionar Estado',
    sport: 'Seleccionar Deporte',
    field: 'Seleccionar Campo',
    chunk: 'Seleccionar Chunk',
    payment_method: 'Seleccionar Método de Pago',
    group: 'Seleccionar Grupo',
    action: 'Seleccionar acción',
    date_range: 'Seleccionar Rango de Fecha',
    view: 'Seleccionar vista',
  },
  status: {
    status: 'Estado',
    active: 'Activo',
    inactive: 'Inactivo',
    pending: 'Pendiente',
    paid: 'Pagado',
    free: 'Gratis',
    hold: 'En espera',
    processing: 'Procesando',
    scheduled: 'Programado',
    cancelled: 'Cancelado',
    successful: 'Exitoso',
    declined: 'Rechazado',
    refunded: 'Devuelto',
    partial_refund: 'Devolución Parcial',
    recording: 'Grabando',
    basic: 'Básico',
    premium: 'Premium',
    tracked: 'Trakeado',
    static: 'Estático',
    confirmed: 'Confirmado',
  },
  users: {
    users: 'Usuarios',
    user: 'Usuario',
    dni: 'DNI',
    type: 'Tipo de Usuario',
    create: 'Crear Usuario',
    edit: 'Editar Usuario',
    show: 'Mostrar Usuario',
    list: 'Lista de Usuarios',
    username: 'Nombre de Usuario',
    name: 'Nombre',
    id: 'ID Usuario',
    firstname: 'Primer Nombre',
    lastname: 'Segundo Nombre',
    fullname: 'Nombre Completo',
    email: 'Correo',
    phone: 'Teléfono',
    gender: 'Género',
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
    bio: 'Bio',
    birthday: 'Cumpleaños',
    address: 'Dirección',
    password: 'Contraseña',
    password_conf: 'Confirmar Contraseña',
    password_not_match: 'Contraseñas no coinciden',
    password_restore: 'Reestablecer contraseña',
    save_password: 'Guardar contraseña',
    account: 'Cuenta',
    security: 'Seguridad',
    info: 'Info',
    overview: 'Información',
    new_password: 'Nueva Contraseña',
    confirm_new_password: 'Confirmar Nueva Contraseña',
    password_hint: 'Asegúrese de que tenga entre 6 y 20 caracteres.',
    contact: 'Contacto',
    users_app: 'Usuarios de la Aplicación',
    country: 'País',
    state: 'Estado',
    state_province_region: 'Estado / Provincia / Región',
    city: 'Ciudad',
    register_date: 'Fecha de Registro',
    register_hour: 'Hora de Registro',
    last_login: 'Última Fecha de Ingrego',
    last_login_hour: 'Última Hora de Ingrego',
    website: 'Sitio web',
    user_activity: 'Actividad de Usuario',
    experience: 'Experiencia',
    age: 'Edad',
    consent: 'Consentimiento',
    has_user: 'tiene usuario en la app con ese número de teléfono/email',
    has_contact: 'Ya existe un contacto con esta información',
  },
  sports: {
    sports: 'Deportes',
    sport: 'Deporte',
    id: 'ID Deporte',
    name: 'Nombre',
    time: 'Tiempo de Juego',
    description: 'Descripción',
    without_description: 'Sin Descripción',
    ranking_group: 'Grupo de Ranking',
    max_score_diff: 'Diferencia de puntuación máxima',
    score_k_factor: 'Factor K de puntuación',
    bkf: 'Factor K base (BKF)',
    df: 'Factor de denominador (DF)',
    nb: 'Base de normalización',
    nc: 'Tapa de normalización (NC)',
    mpf: 'Factor de partidos jugados (MPF)',
    bdr: 'Tasa de decaimiento base (BDR)',
    drf: 'Factor de tasa de decaimiento (DRF)',
    drp: 'Período de tasa de decaimiento (DRP)',
    smsd: 'Diferencia de puntuación máxima en el deporte (SMSD)',
    ssk: 'Factor K de puntuación deportiva (SSK)',
  },
  transactions: {
    id: 'ID de Transacción',
    transactions: 'Transacciones',
    transaction: 'Transacción',
    paid: 'Pagado',
    client: 'Cliente',
    video_date: 'Fecha de Video',
    field: 'Campo',
    start_time: 'Hora Inicio',
    start_date: 'Fecha de inicio',
    end_time: 'Hora Fin',
    end_date: 'Fecha de fin',
    blocked_date: 'Dia Bloqueado',
    video_time: 'Tiempo del Video',
    amount: 'Monto',
    client_amount: 'Monto del Cliente',
    add_payment: 'Agregar Pago',
    pay: 'Pagar',
    date: 'Fecha',
    service_date: 'Fecha de Reserva',
    payment_date: 'Fecha de Pago',
    payment_amount: 'Monto de Pago',
    payment_method: 'Método de Pago',
    payment_medium: 'Medio de Pago',
    medium: 'Medio',
    method: 'Metodo',
    note: 'Nota',
    video_info: 'Video Info',
    user_name: 'Usuario/Nombre',
    transaction_date: 'Fecha de Transacción',
    order_date: 'Fecha de Orden',
    tax: 'Impuesto',
    discount: 'Descuento',
    pay_now: 'Pagar Ahora',
    date_issued: 'Fecha de Incidencia',
    visit_us: 'Visitanos',
    contact_us: 'Contáctanos',
    email_us: 'Envíenos un correo',
    call_us: 'Llámenos',
    price: 'Precio',
    invoice_to: 'Factura Para',
    receipt_to: 'Recibo To',
    seller: 'Vendedor',
    thanks_buy: 'Gracias por asociarse con nosotros.',
    bill_to: 'Factura Para',
    due_date: 'Fecha de vencimiento',
    order: 'Orden',
    orders: 'Ordenes',
    order_by: 'Ordenado por',
    cancel_order: '¿Cancelar Orden?',
    refund: 'Reembolsar si se hizo un pago',
    inactivate_video: 'Inactivar video',
    confirm_cancel: 'Si, Cancelar Orden!',
    from_date: 'Desde Fecha',
    to_date: 'Hasta Fecha',
    description: 'Descripción',
    monthly_report: 'Reporte Mensual',
    monthly_orders: 'Ordenes Mensuales',
    hours: 'Horas',
    purchase_site: 'Sitio de Compra',
    payment_receipt: 'Recibo de Pago',
    agent: 'Agente',
    payment_agent: 'Agente de Pago',
    view_payment: 'Sólo se incluyen los atributos filtrados. Sólo se considera Pagado menos Devuelto.',
    payment_of_booking: 'Pago de Reserva',
  },
  fields: {
    field: 'Campo',
    fields: 'Campos',
    pricing: 'Precios',
    hour: 'Hora',
    hourly_pricing: 'Precios por Hora por Facility',
    user_hourly_price_usd: 'Precio de Hora para Usuario en USD',
    user_hourly_price_eur: 'Precio de Hora para Usuario en EUR',
    user_hourly_price: 'Precio de Hora para Usuario',
    h_pricing: 'Precio H.',
    pricing_type: 'Tipo de Precios',
    general: 'General',
    images: 'Imagenes',
    chunk_frecuency: 'Frecuencia de Fragmentos',
    name: 'Nombre',
    schedule: 'Calendario',
    time_open: 'Hora Apertura',
    time_close: 'Hora Cierre',
    set_time: 'Asignar Horas',
    weekday: 'Dias de Semana',
    open: 'Abre',
    close: 'Cierra',
    open_time: 'Abrir Tiempo',
    close_time: 'Cerrar Tiempo',
    update_open_time: 'Actualizar Hora de Apertura',
    update_close_time: 'Actualizar Hora de Cierre',
    clear_hourly: 'Limpiar Horario',
    link: 'Enlace',
    link_web: 'Enlace Web',
    link_app_landing: 'Enlace App Landing',
    link_video: 'Video Link',
    hourly_discount: 'Descuento por Hora',
    recording_type: 'Tipo de Grabación',
    static_video: 'Vídeo Estático',
    tracked_video: 'Vídeo Rastreado',
    basic_video: 'Vídeo Básico',
    premium_video: 'Vídeo Premium',
    description_images: 'Imagenes Descriptivas del Campo',
    players_qty: 'Cantidad de jugadores',
    booking_hourly_price: 'Precio Reserva por Hora',
    allow_public_cards: 'Permitir tarjetas públicas',
    premium_video_available: 'Video premium disponible',
    shared_fields: 'Campos Compartidos',
    blocked_by_shared_field: 'Bloqueado por campo compartido',
    blocked_time: 'Tiempo de Bloqueo',
    cam_vertical_range: 'Rango vertical de camara',
  },
  facilities: {
    facilities: 'Predios',
    facility: 'Predio',
    signer_name: 'Nombre del Firmante',
    facility_owner: 'Dueño de Predio',
    facility_representative: 'Representante de Predio',
    administrator: 'Administrador',
    add_comission: 'Agregar Comisión?',
    post_Sale: 'Comisión Post-Venta',
    tax_percent: 'Porcentaje de Impueto',
    logo: 'Logo',
    select_avatar: 'Selecionar Avatar',
    short_address: 'Dirección Corta',
    billing_cycle: 'Ciclo de Cobro',
    general: 'General',
    location: 'Locación',
    schedule: 'Esquema',
    images: 'Imagenes',
    bank_account: 'Cuenta Bancaria',
    fanaty: 'FANATY',
    enter_location: 'Introduzca su ubicación',
    monthly_min_orders: 'Ordenes Min Mensual',
    create_group: 'Crear organización',
    add_to_group: 'Agregar a organización',
    google_place_id: 'Google Place Id',
    required_order_phone_number: 'Número de Teléfono Requeridos',
    timezone: 'Zona Horaria',
    time_format: 'Formato de hora',
    video_declaration: 'Declaración de aceptación del vídeo',
    account_number: 'Número de cuenta',
    account_holder_name: 'Nombre del titular de la cuenta',
    account_routing_number: 'Número de routing',
    account_bank_name: 'Nombre del banco de la cuenta',
    connect: 'Conectar',
    on_boarding_link: 'Enlace de On Boarding',
    go_to_dashboard: 'Ir al dashboard',
    link_will_expire: 'Este enlace expirará en 5 minutos!',
  },
  billing_cycle_types: {
    immediately: 'Inmediatamente',
    daily: 'Diariamente',
    monthly: 'Mensualmente',
    manually: 'Manualmente',
    weekly: 'Semanal',
    yearly: 'Anualmente',
    anually: 'Anualmente',
    every_two_weeks: 'Cada 2 Semanas',
    frequency: 'Frecuencia',
  },
  roles: {
    roles: 'Roles',
    role: 'Rol',
    admin: 'Admin',
    facility: 'Predio',
    representative: 'Representante',
    facility_owner: 'Dueño de Predio',
    facility_representative: 'Representante de Predio',
    administrator: 'Administrador',
    name: 'Nombre',
    description: 'Descripción',
    general: 'General',
    permissions: 'Permisos',
  },
  cameras: {
    cameras: 'Cámaras',
    camera: 'Cámara',
    code: 'Código',
    general: 'General',
    thumbnail: 'Miniatura',
    details: 'Detalles',
    mac_address: 'Dirección Mac',
    date: 'Fecha de Miniatura',
    last_date: 'Fecha de Última Miniatura',
    description: 'Descripción',
    name: 'Nombre',
    refresh_chunk: 'Refrescar Chunks',
  },
  videos: {
    videos: 'Videos',
    video: 'video',
    date: 'Fecha',
    time: 'Tiempo',
    highlights: 'Destacados',
    highlight: 'Destacado',
    add_order: 'Agregar Orden',
    minutes: 'Minutos',
    add_order_video: 'Agregar Orden de Video',
    get_app: ' Obten la App Gratis!',
    dont_have_fanaty: '¿No tienes Fanaty?',
    using: ' esta usando Fanaty.',
    app_all: 'Todos están usando Fanaty.',
    video_cost: 'El costo del video será recalculado al confirmar la orden',
    views: 'Vistas',
    show_qr: 'Ver QR',
    general: 'General',
    payment: 'Pago',
    first_video_viewed: 'Primer video visto',
    first_video_added: 'Primer video agregado',
    to_edit: 'para editar',
    start_time: 'Tiempo de inicio',
    make_recurring: 'Hacer recurrente',
    weekdays: 'Días de semana',
    left_video: 'Vídeo Izquierdo',
    right_video: 'Vídeo Derecho',
    left_right_video: 'Vídeo Izquierdo/Derecho',
    video_type: 'tipo de Video',
    ip_address: 'Dirección IP',
    orders_till: 'Las ordenes hasta',
    will_be_created: 'se crearán automáticamente después de guardarlos',
    create_recurring: 'Yes, Crear Order Recurrente!',
    edit_recurring: 'Yes, Editar Order Recurrente!',
    download_app: 'Descargar App',
    repeat: 'Repetir',
    video_not_available: 'No se puede descargar el video después de los 30 días',
  },
  video_details: {
    duration: 'Duración',
    time: 'Tiempo',
    date: 'Fecha',
    facility: 'Predio',
    field: 'Campo',
    sport: 'Deporte',
    amount: 'Precio del video',
    discount: 'Descuento por pre-Orden',
    final_amount: 'Importe final del cliente',
    comission: 'Comisión',
    tax: 'Impuestos',
    fee: 'Tasa de servicios de Fanaty',
    fee_and_tax: 'Tasa de servicios de Fanaty e impuestos',
    group: 'Grupo',
    start_time: 'Tiempo de inicio',
    total_time: 'Tiempo Total',
    refunds: 'Reembolsos',
    video_type: 'Tipo de Video',
  },
  highlights: {
    highlights: 'Destacados',
    highlight: 'Destacado',
    created_date: 'Fecha Creación',
    created_time: 'Hore Creación',
    highlight_name: 'Nombre Destacado',
    created_by: 'Creado por',
    duration: 'Duración',
    category: 'Categoria',
    type: 'Tipo de Highlight',
    goal: 'Gol',
    foul: 'Falta',
    assist: 'Asistencia',
  },
  info: {
    visit_us: 'Visítanos:',
    contact_us: 'Contáctanos:',
    update_available: 'Actualización disponible',
    update_available_message: '¡Una nueva versión de Fanaty está disponible!',
    update_available_link: '¡Por favor, haga click aquí para actualizar a la última versión!',
  },
  payment_methods: {
    payment_methods: 'Métodos de Pago',
    payment_method: 'Método de Pago',
    add_card: 'Agregar Tarjeta',
    card_exp: 'La tarjeta vence en',
    edit_address: 'Editar Dirección',
    billing_address: 'Dirección de Envio',
    company_name: 'Nombre de Empresa',
    billing_email: 'Correo de Envio',
    billing_phone: 'Teléfono de Envio',
    contact: 'Contacto',
    zip_code: 'Código postal',
    edit_address_info: 'Editar tarjeta para facturación futura',
    new_card: 'Nueva Tarjeta',
    new_address_info: 'Nueva tarjeta para facturación futura',
    card_number: 'Número de Tarjeta',
    name_card: 'Nombre en la Tarjeta',
    expiration_date: 'Fecha de Expiración',
    cvv: 'CVV',
    default_card: 'Tarjeta Predeterminada?',
    state: 'Estado',
    edit_billing_address: 'Editar Dirección de Envio',
    edit_Card: 'Editar Tarjeta',
    payment_date: 'Fecha de Pago:',
    payment: 'Pago',
    brand: 'Marca',
    price: 'Precio',
    payment_info: 'Info Pago',
  },
  error_404: {
    page_not_found: 'Página No Encontrada',
    error_message: 'no pudimos encontrar la pagina que estas buscando',
    back_home: 'Volver al Inicio',
  },
  field_app: {
    open_app: 'Abrir en la aplicación Fanaty',
    dont_have: '¿No tienes la aplicación?',
    download: '¡Descárgalo ahora!',
  },
  video_app: {
    watch_video: 'Ver Video',
    reproduced: 'Videos solo pueden ser reproducidos en la aplicación móvil.',
    video_not_found: 'El video no existe!',
    content_not_exist: 'El contenido ya no existe!',
    content_delete: 'Contenido eliminado',
  },
  login: {
    welcome: 'Bienvenido a',
    message: 'Ingresa a tu cuenta y comienza la aventura',
    username: 'Usuario',
    password: 'Contraseña',
    forgot_password: 'Olvidó su Contraseña?',
    login: 'Ingresar',
    welcome_to_platform: 'Plataforma de Gestión',
    message_platform: 'Por favor inicia sesión para gestionar tu organización',
  },
  settings: {
    logout: 'Cerrar Sesión',
    change_password: 'Cambiar Contraseña',
    payment_method_name: 'Nombre',
    payment_method_desc: 'Descripcion',
    onSite_payment_method: 'Metodo de Pago OnSite',
    payment_method_title: 'Metodos de Pago OnSite',
    payment_method_new: 'Nuevo Metodo de Pago OnSite',
  },
  table: {
    item_page: 'Filas por página:',
    sort_by: 'Ordenar Por',
    no_data: 'No hay data disponible',
    loading_text: 'Cargando data...',
  },
  miscellaneous: {
    you_are_not_authorized: 'No estas autorizado',
    you_dont_have_permission: 'No tienes permiso para acceder a esta página. Vete al inicio',
    back_to_home: 'De vuelta al inicio',
    page_not_found: 'Página no encontrada',
    we_couldnt_find_the_page: 'no pudimos encontrar la pagina que estas buscando',
    no_video_on_web: 'Para ver el video descarga la aplicación móvil',
    please_wait: 'Por favor espere...',
  },
  notifications: {
    notifications: 'Notificaciones',
    notification: 'Notificación',
    title_spa: 'Título ESP',
    title_eng: 'Título ENG',
    description_spa: 'Descripción ESP',
    description_eng: 'Descripción ENG',
    button_label_spa: 'Etiqueta de botón ESP',
    button_label_eng: 'Etiqueta de botón ENG',
    image: 'Imagen',
    background: 'Fondo',
    handled_action: 'Acción manejada',
    show_modal: 'Mostrar Modal',
    select_background: 'Seleccione un fondo',
    select_image: 'Seleccione una imagen',
    only_fanaty_user: 'Solo usuario Fanaty?',
    title: 'Título',
    text: 'Texto',
    text_html: 'Text HTML',
    border: 'Borde',
    color: 'Color',
    dismissible: 'Cerrable',
    outlined: 'Contorneado',
    colored_text: 'Texto Coloreado',
    type: 'Tipo',
    icon: 'Icono',
    all_groups: 'Todos los Grupos',
    all_roles: 'Todos los Roles',
    send_by: 'Enviada por',
    created_on: 'Creada el',
  },
  groups: {
    groups: 'Grupos',
    group: 'Grupo',
    min_orders_amount: 'Cantidad mín. de pedido',
    min_orders_filter: 'Filtro de pedido mín.',
    all_groups: 'Todos los grupos',
    monthly_charge_amount: 'Monto Mensual de Suscripción',
    order_reminder_minutes: 'Minutos de recordatorio de SMS',
    notes: 'Notas',
    price_plan: 'Plan de precios',
    price_range: 'Rango de precios',
    hour_price_range: 'Rango de Precio por Hora',
    from: 'De',
    to: 'a',
    up: 'en adelante',
    hours: 'horas',
    hour: 'hora',
    add_range: 'Agregar rango',
    range_close: 'Debe completar el rango de precios!',
    fixed_minimum_amount: 'Monto mínimo fijo',
    price_hour_range: 'Precio por rango de horas',
    only_hourly_price: 'Precio por hora único',
    fanaty_service_fee: 'Tarifa de servicio de pagos online en $',
    fanaty_service_fee_percent: 'Tarifa de servicio de pagos online en %',
    cancellation_hours_qty: 'Politica de Cancelación (hs)',
  },
  statistics: {
    this_month: 'Este Mes',
    overview: 'Nro de Ordenes por Semana',
    orders: 'Ordenes',
    order_number: 'Número de Ordenes',
    app_users_number: 'Usuarios Activos en App',
    app_users_number_tooltip: 'Cantidad de Usuarios que interactúan con cualquier video de los diferentes predios en la App',
    comissions: 'Comisiones',
    active_facility: 'Predios Activos',
    active_field: 'Campos Activos',
    active_camera: 'Camaras Activas',
    active_app_users: 'Usuarios de App Activos',
    active_app_users_tooltip: 'Cantidad de Usuarios que interactúan con cualquier video en la App',
    app_sessions: 'Tasa de Rebote de Usuarios en App',
    app_sessions_tooltip: 'Porcentaje de usuarios que entran a la app pero no interactúan con ninguno de los videos',
    number_videos: 'Vídeos Reproducidos',
    number_videos_tooltip: 'Vídeos Reproducidos',
    unique_views: 'Vistas Unicas',
    unique_views_tooltip: 'Cantidad de reproducciones únicas que tuvo un video de un usuario',
    avg_per_video: 'Avg por video',
    avg_per_video_tooltip: 'Promedio de reproducciones de videos reproducidos',
    sales_by_facility: 'Ventas por predio',
    actions_app_by_user: 'Acciones en App por Usuario',
    actions_app_by_user_tooltip: 'Top 25 de usuarios con más interacciones con los videos de la app',
    registered_app_users: 'Usuarios en App Registrados',
    registered_app_users_by_facility: 'Usuarios Registrados en App por Predio',
    unique_views_by_facility: 'Vistas Únicas por Predio',
    refunds_amount: 'Monto de Reembolsos',
    actives_facility: 'Complejos de activos',
    actives_field: 'Campos Activos',
    actives_camera: 'Cámaras Activas',
  },
  alert_types: {
    success: 'Exito',
    error: 'Error',
    warning: 'Alerta',
    info: 'Info',
  },
  border_types: {
    left: 'Izquierda',
    right: 'Derecha',
    top: 'Arriba',
    bottom: 'Abajo',
  },
  colors: {
    red: 'Rojo',
    orange: 'Naranja',
    yellow: 'Amarillo',
    green: 'Verde',
    blue: 'Azul',
    purple: 'Violeta',
  },
  date_range: {
    this_year: 'Este año',
    this_month: 'Este mes',
    last_month: 'Último mes',
    this_week: 'Esta semana',
    last_week: 'Último week',
    yesterday: 'Ayer',
    today: 'Hoy',
    custom: 'Personalisado',
  },
  notify_players: {
    notify_players: 'Notificar a los Jugadores',
    send_sms: 'Enviar SMS',
    phone: 'Número de teléfono del jugador',
    add_phone: 'Añadir teléfono',
    message: 'Se enviará un enlace de video por SMS a cada jugador agregado.',
    skip: 'Saltar',
    requiredPhone: 'Debe agregar al menos un número de teléfono',
    you_must_add: 'Debes agregar un número de teléfono si haces la orden recurrente.',
    phone_numbers: 'Números de teléfono',
    organizer: 'Organizador',
    organizers: 'Organizadores',
    members: 'Miembros',
    game_organizer: 'Organizador del Juego',
    organizer_phone: 'Número de teléfono del Organizador',
    other_phone_numbers: 'Otros números de teléfono',
  },
  dashboard: {
    shared_highlights: 'Highlights Compartidos',
    numbe_of_highlights: 'Cantidad de enlaces de highlights compartidos',
    created_highlights: 'Highlights Creados',
    numbe_of_highlights_created: 'Cantidad de highlights creados',
    revenue: 'Ganancia',
    orders_revenue: 'Ingresos por pedidos durante un período de tiempo',
    recording_hours: 'Hora(s) de grabación',
    highlights_created: 'Highlights creados',
    shares: 'Compartidos',
    impressions: 'Impresiones',
    est_occupancy_hours: 'Hora(s) de ocupación estimada',
    active_users: 'Usuarios activos',
    new_users: 'Nuevos usuarios',
    total_users: 'Total de usuarios',
    age_range: 'Rango de edad',
    gender: 'Género',
    numbe_of_sales: 'Número de ventas',
    total_occupancy_hours: 'Hora(s) de ocupación total',
  },
  players_group: {
    players_group: 'Grupo de jugadores',
    captain: 'Capitán',
    cocaptain: 'Subcapitan',
    captain_phone_number: 'Número de teléfono del capitán',
    cocaptain_phone_number: 'Número de teléfono del cocapitán',
    captain_email: 'Correo electrónico del capitán',
    cocaptain_email: 'Correo electrónico del cocapitán',
    type: 'Tipo',
    visibility: 'Visibilidad',
    private: 'Privado',
    public: 'Público',
  },
  reservations: {
    reservations: 'Reservas',
    reservation: 'Reservas',
    new_reservation: 'Nueva Reserva',
    reservation_detail: 'Detalle de Reserva',
    reservation_save: 'Guardar Reserva',
    cancel_reservation: 'Cancelar Reserva',
    recurring_detail: 'Detalles de la Recurrencia',
    recurring: 'recurrente',
    one_day: 'Un día',
    pick_up: 'Recogidas',
    pick_up_match: 'Partido Abierto',
    regular_match: 'Partido Regular',
    group_match: 'Partido Grupal',
    regular: 'Regular',
    other: 'Otro',
    blocked: 'Bloqueado',
    academy: 'Academia',
    academies: 'Academias',
    match: 'Partido',
    league: 'Liga',
    birthday: 'Cumpleaños',
    tournament: 'Torneo',
    insights: 'Perspectivas',
    person: 'Persona',
    allow_video: 'Allow Video',
    include_video: 'Incluir Video',
    no_included: 'No incluido',
    player: 'Jugador',
    guest: 'Invitado',
    per_player: 'Por Jugador',
    all_members: 'Todos los miembros del grupo',
    confirmed_players: 'Jugadores confirmados',
    add_group_players: 'Agregar jugadores al grupo',
    add_other_players: 'Agregar otros jugadores',
    balance: 'Balance',
    mark_all_paid: 'Marca todos pagado',
    no_show: 'No Mostrar',
    other_reason: 'Otra Razón',
    reason: 'Razón',
    incomplete_reservations: 'Reservas Incompletas',
    repeat_n_weeks: 'Repetir cada N semanas',
    frequency: 'Frequency',
    incomplete_reason: 'Razón incompleta',
    not_created: 'No creadas por conflictos',
    contact_name: 'Nombre de Contacto',
    without_contact: 'Sin Contacto',
    contact: 'Contacto',
    contacts: 'Contactos',
    contact_phone_numbers: 'Números de teléfono de contacto',
    search_contact: 'Busque su contacto o',
    enter_name_phone: 'Ingrese nombre o teléfono',
    enter_name_phone_email: 'Ingrese nombre, teléfono o correo electrónico',
    add_new_contact: 'Agregar nuevo contacto',
    add_new_participant: 'Agregar nuevo participante',
    add_new_group: 'Agregar nuevo grupo de jugadores',
    group_match_detail: 'Detalle del partido de grupo',
    pick_up_match_detail: 'Detalle del partido abierto',
    regular_match_detail: 'Detalle de partido regular',
    recurrency_detail: 'Detalle de recurrencia',
    skill_level: 'Nivel de habilidad',
    price_per_player: 'Precio por jugador',
    min_players: 'Min jugadores',
    max_players: 'Max jugadores',
    hours_before_cancel: 'Horas antes de cancelar el partido (Si los jugadores mínimos no se unen)',
    reservation_link_copied: '¡Enlace de reserva copiado!',
    reservation_link_error: '¡Error al copiar el enlace de reserva!',
    delete_all_pending: 'Eliminar todas las reservas pendientes',
    no_ncluded: 'No incluido',
    scheduled: 'Programado',
    reservation_link: 'Enlace de la reserva',
    reservation_schedule_conflict: 'Conflicto en el horario de reserva',
    days_with_conflict: 'Días con conflicto',
    create_reservations_without_conflict: 'Crear reservas sin conflicto',
    no_active_fields: 'El complejo está cerrada este día',
    payment_status: 'Estado de pago',
    inc_taxes_add_ons: 'incluye impuestos adicionales',
    mark_as_paid: 'Marcar como Pagado',
    video_recording: 'Grabación de vídeo',
    flat_rate: 'Tarifa plana',
    per_participant: 'Por participante',
    pricing_method: 'Método de precios',
    participants: 'Participantes',
    participant: 'Participante',
    not_going: 'No asistieron',
    owner: 'Dueño',
    organizer: 'Organizador',
    optional: 'opcional',
    tota_includ_taxes_addons: 'Total incluye impuestos y adicionales',
    date_and_time: 'Fecha y hora',
    quantity_participants_message: 'La cantidad de participantes no puede ser menor que la cantidad de jugadores que se han registrado para jugar.',
    add_participant: 'Agregar participante',
    select_all: 'Selecionar todo',
    unselect_all: 'Deselecionar todo',
    mark_selected_as_paid: 'Marcar seleccionado como pagado',
    enter_number_participants: 'Ingrese el número de participantes',
    discart_bookibg_message: '¿Estás seguro de que quieres descartar esta Reserva?',
    balance_pass: 'No se cargará saldo al organizador porque el pago no se realizó a través de la aplicación.',
    balance_charged_1: 'se cargó a la tarjeta de crédito del organizador que termina en',
    balance_charged_2: 'porque no se pagó después de',
    balance_pending_1: 'se cargará a la tarjeta de crédito del organizador que termina en',
    balance_pending_2: 'Si no se paga antes de',
  },
  reservation_detail: {
    reservation_detail: 'Detalle de Reserva',
    edit_details: 'Editar Detalles',
    chat: 'Chat',
    notes: 'Notas',
    history: 'Historial',
    origin: 'Origen',
    price_per_participant: 'Precio por participante',
    cancel_booking: 'Cancelar Reserva',
    cancel_booking_info: 'Si se realizó el pago, se reembolsará automáticamente (las tarifas de servicio en línea no son reembolsables). Si se encargó un video, se cancelará sin cargo o se emitirá un reembolso si la reserva aún no se ha iniciado.',
    delete_booking: 'Borrar reserva',
    delete_only_this_booking: 'Borrar sólo esta reserva',
    delete_all_the_following: 'Borrar todas las siguientes',
    view_receipt: 'Ver recibo',
    delete_participant: 'Eliminar participante',
  },
  skill_lavels: {
    rookie: 'Novato',
    intermediate: 'Intermedio',
    pro: 'Pro',
  },
  organizations: {
    organization: 'Organización',
    organizations: 'Organizaciones',
    organization_owner: 'Organización dueño',
    all_organization: 'Todas las Organizaciones',
  },
  products: {
    products: 'Productos',
    product: 'Producto',
    product_list: 'Listado de Productos',
    product_add: 'Agregar Producto',
    name: 'Nombre',
    dr: 'Dias de Inventario',
    dozi: 'Dia de Cero Inventario',
    stock: 'Existencia Actual',
    products_categories: 'Categorias de Productos',
    products_categories_name: 'Nombre',
    products_categories_desc: 'Descripción',
    inventory: 'Inventario',
    current_inventory: 'Current Inventory',
    inventory_creator: 'Creador',
    adjustment_cost: 'Costo del Ajuste',
    adjusted_products: 'Productos Ajustados',
    inventory_id: 'ID',
    inventory_date: 'Fecha',
    mdwi: 'MDWI',
    categories: 'Categorias',
    inventory_item: 'Item',
    inventory_system: 'System',
    inventory_stock: 'Stock',
    inventory_plus: '+/-',
    reason: 'Razón',
    obs: 'Obs',
    inventory_adjustment: 'Ajuste de Inventario',
    inventory_observation: 'Observaciones',
    product_adjustment: 'Ajuste de Producto',
    unit: 'Unidad',
    system: 'Existencia en Sistema',
    attach: 'Adjuntar',
    observation: 'Observación',
    damaged: 'Damaged',
    expired: 'Expired',
    lost: 'Lost',
    product_charge: 'Carga de Producto',
    qty_charge: 'Quantity Charge',
    qty: 'Cantidad',
    new_stock: 'Nuevo Inventario',
    confirm: 'Confirmar',
    cart: 'Carro',
    payment: 'Pago',
    confirmation: 'Confirmación',
    guest: 'Invitado',
    visibility: 'Visibilidad',
    individual: 'Individual',
    both: 'Ambos',
  },
  terminals: {
    terminals: 'Terminales',
    terminal: 'Terminal',
    name: 'Nombre',
    description: 'Descripción',
    stripe_reader: 'Lector Stripe',
    reference_reader: 'Referencia del Lector',
    operators: 'Operadores',
    serial: 'Serial',
    terminal_name: 'Nombre del Terminal',
  },
  checkout: {
    sales_details: 'Detalles de venta',
    price_details: 'Detalles del precio',
    taxes_and_fees: 'Impuestos y honorarios',
    tip: 'Propina',
    place_order: 'Realizar pedido',
    open_tabs: 'Pestañas abiertas',
    open_tab: 'Abrir pestaña',
    keep_open: 'Mantener abierto',
    reader: 'Lector',
    cash: 'Efectivo',
    manual: 'Manual',
    others: 'OTROS',
    continue_to_read: 'Pulsa continuar para leer la tarjeta',
    cash_amount: 'Monto en efectivo',
    change: 'Cambio',
    thank_you: 'Gracias! 😇',
    we_sent_an_email: 'Le enviamos un correo electrónico a',
    with_your_invoice: 'con tu factura.',
    finish: 'Finalizar',
  },
  video_cancelled: {
    video_cancelled: 'Vídeo cancelado',
    game_cancelled: 'Juego cancelado',
    game_info: 'Este juego ha sido cancelado. Buscar juegos para ver si se creó uno nuevo.',
    search_games: 'Buscar juegos',
    search_videos: 'Buscar Videos',
    download_app: 'Descarga la aplicación',
    not_available: 'Vídeo no disponible',
    not_valid: 'Vídeo no valido',
    click_search_videos: 'Haga clic en buscar videos para encontrar otros.',
  },
  questions: {
    are_you_sure: '¿Estas seguro?',
    reason_by_modified: 'Se modificara el nombre de la razón en el reporte. Si quiere otra razon, puede ser agregada y esta puede inactivarse.',
    yes: 'Si',
    no: 'No',
  },
  locales: {
    languages: 'Idiomas',
    language: 'Idiomas',
    es: 'Español',
    en: 'Inglés',
  },
  contacts: {
    contacts: 'Contactos',
    contact: 'Contacto',
  },
}
