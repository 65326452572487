/* eslint-disable no-bitwise */
// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch, ref, computed } from '@vue/composition-api'
import currencyFormatter from 'currency-formatter'
import moment from 'moment'
import { success, error } from '@core/utils/toasted'
import { copyText } from 'vue3-clipboard'
import { SUPPORT_NUMBER } from './constans'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

// Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
export const kFormatter = num => {
  if (Math.abs(num) > 999) return `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}K`

  return Math.sign(num) * Math.abs(num)
}

export const formatDateSlash = date => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  if (year) return `${day}/${month}/${year}`

  return `${month}/${year}`
}

export const formatDateBar = date => {
  const arryData = date.split('/')
  const arryReverse = arryData.reverse()

  return arryReverse.join('-')
}

export const formatUSADate = date => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  return `${month}-${day}-${year}`
}

export const getAge = date => {
  const ageInMilliseconds = new Date() - new Date(date)

  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365) // convert to years
}

export const getAgeOld = (dA, dB) => {
  const dateA = moment(dA)
  const dateB = moment(dB)

  return dateA.from(dateB) // time old
}

export const getDateDiff = (dA, dB) => {
  const dateA = moment(dA)
  const dateB = moment(dB)

  const years = dateB.diff(dateA, 'years')
  const months = dateB.diff(dateA, 'months')
  const weeks = dateB.diff(dateA, 'weeks')
  if (years > 0) return `${years} ${years === 1 ? 'year' : 'years'}`
  if (months > 0) return `${months} ${months === 1 ? 'month' : 'months'}`

  return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`
}

export const standardizeDate = (now, passed) => {
  const dateNow = new Date(now)
  const datePassed = new Date(passed)

  // Create Intl.DateTimeFormat objects for the two time zones
  const locale = 'sv-SE'
  const options = {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  const formatter1 = new Intl.DateTimeFormat(locale, options)
  const formatter2 = new Intl.DateTimeFormat(locale, options)

  // Get the formatted strings of dates in their respective time zones
  const formattedDateNow = formatter1.format(dateNow)
  const formattedDatePassed = formatter2.format(datePassed)

  // Convert formatted strings back to Date objects in the same time zone
  const convertedDateNow = new Date(formattedDateNow)
  const convertedDatePassed = new Date(formattedDatePassed)

  return {
    standardizedNow: convertedDateNow,
    standardizedPassed: convertedDatePassed,
  }
}

export const isDateToday = (now, date) => {
  const { standardizedNow, standardizedPassed } = standardizeDate(now, date)

  // Compare converted dates
  if (standardizedNow.getTime() === standardizedPassed.getTime()) return true

  return false
}

export const isDatePassed = (now, date) => {
  const { standardizedNow, standardizedPassed } = standardizeDate(now, date)

  // Compare converted dates
  if (standardizedNow <= standardizedPassed) return false

  return true
}

export const formatCurrency = amount => currencyFormatter.format(amount, { locale: 'en-US' })

export const unformatCurrency = amount => currencyFormatter.unformat(amount, { locale: 'en-US' })

export const openTab = (url, target = '_self') => {
  const a = window.document.createElement('a')
  a.target = target
  a.href = url

  // Dispatch fake click
  const e = window.document.createEvent('MouseEvents')
  e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}

export const openNewTab = url => openTab(url, '_blank')

export const getOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS'
  }

  if (ua.indexOf('Win') !== -1) {
    return 'Windows'
  }

  if (ua.indexOf('Mac') !== -1) {
    return 'MacOS'
  }

  return 'Other'
}

export const isAndroid = () => getOS() === 'Android'

export const isIOS = () => getOS() === 'iOS'

// refactor this util, local config file whit urls
export const downloadAppByOS = (urlAndroid, urlIOS, urlOther, openApp = false) => {
  try {
    if (isAndroid()) {
      if (openApp) {
        // setTimeout(() => { window.location = urlAndroid }, 25)
        // window.location = 'https://play.google.com/store/apps/details?id=com.fanaty'

        // new
        window.location = urlAndroid
        const clickedAt = +new Date()
        setTimeout(() => {
          if (+new Date() - clickedAt < 2000) {
            window.location = 'https://play.google.com/store/apps/details?id=com.fanaty'
          }
        }, 500)
      } else window.open(urlAndroid, '_blank')
    }
    if (isIOS()) {
      if (openApp) {
        // setTimeout(() => { window.location = urlIOS }, 25)
        // window.location = 'https://apps.apple.com/us/app/fanaty/id1461051554'

        // new
        window.open().location = urlIOS
        const clickedAt = +new Date()
        setTimeout(() => {
          if (+new Date() - clickedAt < 2000) {
            window.open().location = 'https://apps.apple.com/us/app/fanaty/id1461051554'
          }
        }, 500)
      } else openTab(urlIOS)
    }
    if (getOS() === 'MacOS') openTab(urlOther)
    if (getOS() === 'Windows' || getOS() === 'Other') window.open(urlOther, '_blank')
  } catch (e) {
    openTab('https://fanaty.com/download')
  }
}

const timer = ref(null)
export const defaultDownloadAppByOS = (newTab = false) => {
  clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    try {
      if (isAndroid()) {
        if (!newTab) {
          window.location = 'https://play.google.com/store/apps/details?id=com.fanaty'
        } else window.open('https://play.google.com/store/apps/details?id=com.fanaty', '_blank')
      }
      if (isIOS()) {
        if (!newTab) {
          // window.open().location = 'https://apps.apple.com/us/app/fanaty/id1461051554'
          openTab('https://apps.apple.com/us/app/fanaty/id1461051554', '_self')
        } else openTab('https://apps.apple.com/us/app/fanaty/id1461051554', '_blank')
      }
      if (getOS() === 'MacOS') {
        openTab('https://www.fanaty.com')
      }
      if (getOS() === 'Windows' || getOS() === 'Other') {
        if (!newTab) window.location = 'https://www.fanaty.com'
        else window.open('https://www.fanaty.com', '_blank')
      }
    } catch (e) {
      openTab('https://fanaty.com')
    }
  }, 200)
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isObjEmpty = value => Object.keys(value).length === 0

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
  // eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  /* eslint-enable */
}

export const getRGBColor = (colorStr, theme) => {
  const rgbColor = hexToRgb(theme.currentTheme[colorStr])

  return `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`
}

export const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true

  return false
}

// Custom vertical menu
export const openChat = () => {
  if (!window.fcWidget.isOpen()) {
    window.fcWidget.open()
  } else {
    window.fcWidget.close()
  }
}

export const copy = () => {
  if (isMobile()) {
    window.open(`tel:${SUPPORT_NUMBER}`)
  } else {
    copyText(SUPPORT_NUMBER, undefined, e => {
      if (e) {
        error('Can not copy')
      } else {
        success('Copied')
      }
    })
  }
}

export const roundNum = num => Math.round(num * 100) / 100

export const isDev = computed(() => {
  const ENV = process.env
  if (['localhost', '127.0.0.1'].includes(window.location.hostname) || ENV.VUE_APP_DEPLOYMENT_ENV.includes('staging')) return true

  return false
})

export const trimSpace = str => {
  if (!str) return null

  // Create a regular expression that matches one or more whitespace characters
  const whitespaceRegex = /\s+/g

  // Replace all instances of one or more whitespace characters with a single space
  const trimmedStr = str.replace(whitespaceRegex, ' ')

  // Trim any remaining whitespace at the beginning and end of the string
  return trimmedStr.trim()
}

export const after30Days = passDate => {
  const currentDateMinus30 = moment().subtract(30, 'days')
  const passDateMoment = moment(passDate)

  if (passDateMoment < currentDateMinus30) {
    return true
  }

  return false
}

export const downloadVideo = url => {
  const link = document.createElement('a')
  link.href = url
  link.type = 'video/mp4'
  link.download = 'FanatyVideo.mp4'
  link.click()
}

export const validateDateRange = (dateFrom, dateTo) => {
  const dateFromMoment = moment(dateFrom)
  const dateToMoment = moment(dateTo)

  return dateFromMoment.isAfter(dateToMoment)
}

export const toCapitalize = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })

  return capitalizedArray.join(' ')
}

export const formatStdDate = (dateStr = '2024-11-15 23:35', dateFormat = 'date', is12Hour = true, lang = 'en') => {
  let format = 'ddd, MMM DD, YYYY'
  if (dateFormat === 'date') format = 'ddd, MMM DD, YYYY'
  if (dateFormat === 'datelist') format = 'YYYY - MMM - DD'
  if (dateFormat === 'datetime-list') {
    format = is12Hour ? 'YYYY - MMM - DD, h:mm A' : 'YYYY - MMM - DD, HH:mm'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'datetime') format = is12Hour ? 'ddd, MMM DD, YYYY h:mm A' : 'ddd, MMM DD, YYYY HH:mm'
  if (dateFormat === 'datetime-custom') {
    format = is12Hour ? 'ddd, MMM DD, YYYY h:mm A' : 'ddd, MMM DD, YYYY HH:mm'

    return toCapitalize(moment(dateStr, 'DD/MM/YYYY HH:mm:ss').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'time') {
    format = is12Hour ? 'h:mm A' : 'HH:mm'

    return toCapitalize(moment(`2000-01-01 ${dateStr}`, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'video-card-date') {
    format = 'MMM DD, YYYY'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'video-card-time') {
    format = is12Hour ? 'ddd, h:mm A' : 'ddd, HH:mm'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }

  return toCapitalize(moment(dateStr).locale(lang).format(format)).replaceAll('.', '')
}

export const generateGradient = (hexColor, numColores = 10) => {
  // // Convertir el color hexadecimal a RGB
  // const r = parseInt(hexColor.slice(1, 3), 16)
  // const g = parseInt(hexColor.slice(3, 5), 16)
  // const b = parseInt(hexColor.slice(5, 7), 16)

  // const coloresDegradados = []

  // for (let i = 0; i < numColores; i += 1) {
  //   const factor = i / (numColores - 1) // Factor de degradado
  //   const nuevoR = Math.round(r + (255 - r) * factor) // Hacer más claro
  //   const nuevoG = Math.round(g + (255 - g) * factor) // Hacer más claro
  //   const nuevoB = Math.round(b + (255 - b) * factor) // Hacer más claro

  //   // Convertir de nuevo a hexadecimal
  //   // eslint-disable-next-line no-bitwise
  //   const colorHex = `#${((1 << 24) + (nuevoR << 16) + (nuevoG << 8) + nuevoB).toString(16).slice(1)}`
  //   coloresDegradados.push(colorHex)
  // }

  // return coloresDegradados

  // Función para convertir un color hexadecimal a RGB
  function getHexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16)
    // eslint-disable-next-line no-bitwise
    const r = (bigint >> 16) & 255
    // eslint-disable-next-line no-bitwise
    const g = (bigint >> 8) & 255
    // eslint-disable-next-line no-bitwise
    const b = bigint & 255

    return [r, g, b]
  }

  // Función para convertir RGB a hexadecimal
  function getRgbToHex(r, g, b) {
    // eslint-disable-next-line no-bitwise
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
  }

  const rgb = getHexToRgb(hexColor)
  const shades = []

  for (let i = 0; i < numColores; i += 1) {
    const factor = (i + 1) / (numColores + 1)
    const newColor = rgb.map(value => Math.round(value * (1 - factor)))
    shades.push(getRgbToHex(...newColor))
  }

  for (let i = 0; i < numColores; i += 1) {
    const factor = (i + 1) / (numColores + 1)
    const newColor = rgb.map(value => Math.round(value + (255 - value) * factor))
    shades.push(getRgbToHex(...newColor))
  }

  return shades
}

export const generateColorShades = (hex = '#ff3232', numberOfShades = 5) => {
  // Función para convertir un color hexadecimal a RGB
  function getHexToRgb(colorHex) {
    const bigint = parseInt(colorHex.slice(1), 16)
    // eslint-disable-next-line no-bitwise
    const r = (bigint >> 16) & 255
    // eslint-disable-next-line no-bitwise
    const g = (bigint >> 8) & 255
    // eslint-disable-next-line no-bitwise
    const b = bigint & 255

    return [r, g, b]
  }

  // Función para convertir RGB a hexadecimal
  function getRgbToHex(r, g, b) {
    // eslint-disable-next-line no-bitwise
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
  }

  const rgb = getHexToRgb(hex)
  const shades = []

  for (let i = 0; i < numberOfShades; i += 1) {
    const factor = (i + 1) / (numberOfShades + 1)
    const newColor = rgb.map(value => Math.round(value * (1 - factor)))
    shades.push(getRgbToHex(...newColor))
  }

  for (let i = 0; i < numberOfShades; i += 1) {
    const factor = (i + 1) / (numberOfShades + 1)
    const newColor = rgb.map(value => Math.round(value + (255 - value) * factor))
    shades.push(getRgbToHex(...newColor))
  }

  return shades
}
